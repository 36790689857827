import { useTranslation } from 'react-i18next';
import './PriceTable.css';

const PriceTable = () => {  
    const { t } = useTranslation();

    return (
        <div className="PriceTable-wrapper">           
            {/* COWORKING PRICES (AMD) */}
            <div className="PriceTable">
                <div className="PriceTable__head">
                    <div className="PriceTable__col PriceTable__col_tech col-content_center">
                        <div className="PriceTable__head__row"><h2 className="head__title PriceTable__title">{t('coworking.coworkingPricesTable.title')}</h2></div>
                    </div>
                   
                    <div className="PriceTable__col col-content_center">
                        <div className="PriceTable__head__row"><h2 className="head__title">{t('coworking.coworkingPricesTable.col2.title')}</h2></div>
                    </div>
                   
                    <div className="PriceTable__col col-content_center">
                        <div className="PriceTable__head__row"><h2 className="head__title">{t('coworking.coworkingPricesTable.col3.title')}</h2></div>
                    </div>
                   
                    <div className="PriceTable__col col-content_center">
                        <div className="PriceTable__head__row"><h2 className="head__title">{t('coworking.coworkingPricesTable.col4.title')}</h2></div>
                    </div>
                   
                    <div className="PriceTable__col col-content_center">
                        <div className="PriceTable__head__row"><h2 className="head__title">{t('coworking.coworkingPricesTable.col5.title')}</h2></div>
                    </div>

                    <div className="PriceTable__col col-content_center">
                        <div className="PriceTable__head__row"><h2 className="head__title">{t('coworking.coworkingPricesTable.col6.title')}</h2></div>
                    </div>
                </div>
                <div className="PriceTable__body">
                    <div className="PriceTable__col PriceTable__text-color_white PriceTable__col_tech col-content_center PriceTable__text PriceTable__text-color_white">
                        <div className="PriceTable__row PriceTable__row_tech">{t('coworking.coworkingPricesTable.col1.row1')}</div>
                        <div className="PriceTable__row PriceTable__row_tech">{t('coworking.coworkingPricesTable.col1.row2')}</div>
                        <div className="PriceTable__row PriceTable__row_tech">{t('coworking.coworkingPricesTable.col1.row3')}</div>
                        <div className="PriceTable__row PriceTable__row_tech">{t('coworking.coworkingPricesTable.col1.row4')}</div>
                        <div className="PriceTable__row PriceTable__row_tech">{t('coworking.coworkingPricesTable.col1.row5')}</div>
                        <div className="PriceTable__row PriceTable__row_tech">{t('coworking.coworkingPricesTable.col1.row6')}</div>
                    </div>
                    
                    <div className="PriceTable__col PriceTable__col_bg col-content_center PriceTable__text PriceTable__text-color_blue PriceTable__col_first">
                        <div className="PriceTable__row PriceTable__row_num">{t('coworking.coworkingPricesTable.col2.row1')}</div>
                        <div className="PriceTable__row">{t('coworking.coworkingPricesTable.col2.row2')}</div>
                        <div className="PriceTable__row">{t('coworking.coworkingPricesTable.col2.row3')}</div>
                        <div className="PriceTable__row">{t('coworking.coworkingPricesTable.col2.row4')}</div>
                        <div className="PriceTable__row">{t('coworking.coworkingPricesTable.col2.row5')}</div>
                        <div className="PriceTable__row">{t('coworking.coworkingPricesTable.col2.row6')}</div>
                    </div>
                   
                    <div className="PriceTable__col PriceTable__col_bg col-content_center PriceTable__text PriceTable__text-color_blue">
                        <div className="PriceTable__row PriceTable__row_num">{t('coworking.coworkingPricesTable.col3.row1')}</div>
                        <div className="PriceTable__row">{t('coworking.coworkingPricesTable.col3.row2')}</div>
                        <div className="PriceTable__row">{t('coworking.coworkingPricesTable.col3.row3')}</div>
                        <div className="PriceTable__row">{t('coworking.coworkingPricesTable.col3.row4')}</div>
                        <div className="PriceTable__row">{t('coworking.coworkingPricesTable.col3.row5')}</div>
                        <div className="PriceTable__row">{t('coworking.coworkingPricesTable.col3.row6')}</div>
                    </div>
                   
                    <div className="PriceTable__col PriceTable__col_bg col-content_center PriceTable__text PriceTable__text-color_blue">
                        <div className="PriceTable__row PriceTable__row_num">{t('coworking.coworkingPricesTable.col4.row1')}</div>
                        <div className="PriceTable__row">{t('coworking.coworkingPricesTable.col4.row2')}</div>
                        <div className="PriceTable__row">{t('coworking.coworkingPricesTable.col4.row3')}</div>
                        <div className="PriceTable__row">{t('coworking.coworkingPricesTable.col4.row4')}</div>
                        <div className="PriceTable__row">{t('coworking.coworkingPricesTable.col4.row5')}</div>
                        <div className="PriceTable__row">{t('coworking.coworkingPricesTable.col4.row6')}</div>
                    </div>
                    
                    <div className="PriceTable__col PriceTable__col_bg col-content_center PriceTable__text PriceTable__text-color_blue">
                        <div className="PriceTable__row PriceTable__row_num">{t('coworking.coworkingPricesTable.col5.row1')}</div>
                        <div className="PriceTable__row">{t('coworking.coworkingPricesTable.col5.row2')}</div>
                        <div className="PriceTable__row">{t('coworking.coworkingPricesTable.col5.row3')}</div>
                        <div className="PriceTable__row">{t('coworking.coworkingPricesTable.col5.row4')}</div>
                        <div className="PriceTable__row">{t('coworking.coworkingPricesTable.col5.row5')}</div>
                        <div className="PriceTable__row">{t('coworking.coworkingPricesTable.col5.row6')}</div>
                    </div>
                   
                    <div className="PriceTable__col PriceTable__col_bg col-content_center PriceTable__text PriceTable__text-color_blue PriceTable__col_last">
                        <div className="PriceTable__row PriceTable__row_num">{t('coworking.coworkingPricesTable.col6.row1')}</div>
                        <div className="PriceTable__row">{t('coworking.coworkingPricesTable.col6.row2')}</div>
                        <div className="PriceTable__row">{t('coworking.coworkingPricesTable.col6.row3')}</div>
                        <div className="PriceTable__row">{t('coworking.coworkingPricesTable.col6.row4')}</div>
                        <div className="PriceTable__row">{t('coworking.coworkingPricesTable.col6.row5')}</div>
                        <div className="PriceTable__row">{t('coworking.coworkingPricesTable.col6.row6')}</div>
                    </div>
                </div>

                <div className="PriceTable__footer">
                    <p className="PriceTable__footer-desc">{t('coworking.coworkingPricesTable.desc1')}</p>
                    <p className="PriceTable__footer-desc">{t('coworking.coworkingPricesTable.desc2')}</p>
                </div>
            </div>

            {/* Coworking options: */}
            {/* <h2 className="Coworking__title Coworking__title_center PriceTable__title">{t('coworking.coworkingOptions.title')}</h2>

            <div className="PriceTable PriceTable_padding">
                <div className="PriceTable__body">
                    <div className="PriceTable__col PriceTable__col_tech col-content_center PriceTable__text PriceTable__text-color_white">
                        <div className="PriceTable__row  PriceTable__row_tech PriceTable__row_fixed-height">
                            {t('coworking.coworkingOptions.col1.title')}
                        </div>
                        <div className="PriceTable__row  PriceTable__row_tech PriceTable__row_fixed-height">
                            {t('coworking.coworkingOptions.col2.title')}
                        </div>
                        <div className="PriceTable__row PriceTable__row_tech PriceTable__row_fixed-height">
                            {t('coworking.coworkingOptions.col3.title')}
                        </div>
                        <div className="PriceTable__row PriceTable__row_tech PriceTable__row_fixed-height">
                            {t('coworking.coworkingOptions.col4.title')}
                        </div>
                        <div className="PriceTable__row PriceTable__row_tech PriceTable__row_fixed-height">
                            {t('coworking.coworkingOptions.col5.title')}
                        </div>
                    </div>

                    <div className="PriceTable__col PriceTable__col_bg col-content_center PriceTable__text PriceTable__col_first PriceTable__text-color_blue">
                        <div className="PriceTable__row PriceTable__row_fixed-height">
                            {t('coworking.coworkingOptions.col1.row1')}
                        </div>
                        <div className="PriceTable__row PriceTable__row_fixed-height">
                            {t('coworking.coworkingOptions.col2.row1')}
                        </div>
                        <div className="PriceTable__row PriceTable__row_fixed-height">
                            {t('coworking.coworkingOptions.col3.row1')}
                        </div>
                        <div className="PriceTable__row PriceTable__row_fixed-height">
                            {t('coworking.coworkingOptions.col4.row1')}
                        </div>
                        <div className="PriceTable__row PriceTable__row_fixed-height">
                            {t('coworking.coworkingOptions.col5.row1')}
                        </div>
                    </div>

                    <div className="PriceTable__col PriceTable__col_bg col-content_center PriceTable__text PriceTable__text-color_blue">
                        <div className="PriceTable__row PriceTable__row_fixed-height">
                            {t('coworking.coworkingOptions.col1.row2')}
                        </div>
                        <div className="PriceTable__row PriceTable__row_fixed-height">
                            {t('coworking.coworkingOptions.col2.row2')}
                        </div>
                        <div className="PriceTable__row PriceTable__row_fixed-height">
                            {t('coworking.coworkingOptions.col3.row2')}
                        </div>
                        <div className="PriceTable__row PriceTable__row_fixed-height">
                            {t('coworking.coworkingOptions.col4.row2')}
                        </div>
                        <div className="PriceTable__row PriceTable__row_fixed-height">
                            {t('coworking.coworkingOptions.col5.row2')}
                        </div>
                    </div>

                    <div className="PriceTable__col PriceTable__col_bg col-content_center PriceTable__text PriceTable__col_last PriceTable__text-color_blue">
                        <div className="PriceTable__row PriceTable__row_fixed-height">
                            {t('coworking.coworkingOptions.col1.row3')}
                        </div>
                        <div className="PriceTable__row PriceTable__row_fixed-height">
                            {t('coworking.coworkingOptions.col2.row3')}
                        </div>
                        <div className="PriceTable__row PriceTable__row_fixed-height">
                            {t('coworking.coworkingOptions.col3.row3')}
                        </div>
                        <div className="PriceTable__row PriceTable__row_fixed-height">
                            {t('coworking.coworkingOptions.col4.row3')}
                        </div>
                        <div className="PriceTable__row PriceTable__row_fixed-height">
                            {t('coworking.coworkingOptions.col5.row3')}
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <h2 className="Coworking__title Coworking__title_center PriceTable__title">{t('coworking.meetingRoomPricesTable.title')}</h2> */}

            {/* <div className="PriceTable">
                <div className="PriceTable__head">
                    <div className="PriceTable__col PriceTable__col_tech col-content_center">
                        <div className="PriceTable__head"><h2 className="head__title">{t('coworking.meetingRoomPricesTable.col1.title')}</h2></div>
                    </div>
                    <div className="PriceTable__col">
                        <div className="PriceTable__head">{t('coworking.meetingRoomPricesTable.col2.title')}</div>
                    </div>
                    <div className="PriceTable__col col-content_center">
                        <div className="PriceTable__head"><h2 className="head__title">{t('coworking.meetingRoomPricesTable.col3.title')}</h2></div>
                    </div>
                    <div className="PriceTable__col col-content_center">
                        <div className="PriceTable__head"><h2 className="head__title">{t('coworking.meetingRoomPricesTable.col4.title')}</h2></div>
                    </div>
                </div>
                <div className="PriceTable__body">
                    <div className="PriceTable__col PriceTable__col_tech col-content_center PriceTable__text  PriceTable__text-color_white">
                        <div className="PriceTable__row"><div className="PriceTable__row_tech">{t('coworking.meetingRoomPricesTable.col1.row1')}</div></div>
                        <div className="PriceTable__row"><div className="PriceTable__row_tech">{t('coworking.meetingRoomPricesTable.col1.row2')}</div></div>
                        <div className="PriceTable__row"><div className="PriceTable__row_tech">{t('coworking.meetingRoomPricesTable.col1.row3')}</div></div>
                        <div className="PriceTable__row"><div className="PriceTable__row_tech">{t('coworking.meetingRoomPricesTable.col1.row4')}</div></div>
                        <div className="PriceTable__row"><div className="PriceTable__row_tech">{t('coworking.meetingRoomPricesTable.col1.row5')}</div></div>
                    </div>
                    <div className="PriceTable__col PriceTable__col_bg col-content_center PriceTable__text PriceTable__text-color_blue PriceTable__col_first">
                        <div className="PriceTable__row">{t('coworking.meetingRoomPricesTable.col2.row1')}</div>
                        <div className="PriceTable__row">{t('coworking.meetingRoomPricesTable.col2.row2')}</div>
                        <div className="PriceTable__row">{t('coworking.meetingRoomPricesTable.col2.row3')}</div>
                        <div className="PriceTable__row">{t('coworking.meetingRoomPricesTable.col2.row4')}</div>
                        <div className="PriceTable__row">{t('coworking.meetingRoomPricesTable.col2.row5')}</div>
                    </div>
                    <div className="PriceTable__col PriceTable__col_bg col-content_center PriceTable__text PriceTable__text-color_blue">
                        <div className="PriceTable__row">{t('coworking.meetingRoomPricesTable.col3.row1')}</div>
                        <div className="PriceTable__row">{t('coworking.meetingRoomPricesTable.col3.row2')}</div>
                        <div className="PriceTable__row">{t('coworking.meetingRoomPricesTable.col3.row3')}</div>
                        <div className="PriceTable__row">{t('coworking.meetingRoomPricesTable.col3.row4')}</div>
                        <div className="PriceTable__row">{t('coworking.meetingRoomPricesTable.col3.row5')}</div>
                    </div>
                    <div className="PriceTable__col PriceTable__col_bg col-content_center PriceTable__text PriceTable__text-color_blue PriceTable__col_last">
                        <div className="PriceTable__row">{t('coworking.meetingRoomPricesTable.col4.row1')}</div>
                        <div className="PriceTable__row">{t('coworking.meetingRoomPricesTable.col4.row2')}</div>
                        <div className="PriceTable__row">{t('coworking.meetingRoomPricesTable.col4.row3')}</div>
                        <div className="PriceTable__row">{t('coworking.meetingRoomPricesTable.col4.row4')}</div>
                        <div className="PriceTable__row">{t('coworking.meetingRoomPricesTable.col4.row5')}</div>
                    </div>
                </div>
            </div> */}
        </div>
    );
}
  
export default PriceTable;
  