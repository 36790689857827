import { useTranslation } from 'react-i18next';
import { SectionInfo, SubTitle } from '../';
import './Coffeeshop.css';

const Coffeeshop = () => {
  const { t } = useTranslation();

  return (
    <div className="Coffeeshop">
        <section className="Coffeeshop-media"></section>

        <SectionInfo
            title={() => <SubTitle>{t('coffeeshop.title1')}</SubTitle>}
            color="var(--brand-color-1)"
        />
         <SectionInfo
            title={() => <SubTitle>{t('coffeeshop.title2')}</SubTitle>}
            color="var(--brand-color-1)"
            description={() =>
               <ul className="Coffeeshop__list">
                  <li className="Coffeeshop__list-item Coffeeshop__icon_beans">
                     {t('coffeeshop.listItem1')}
                  </li>
                  <li className="Coffeeshop__list-item Coffeeshop__icon_coffee">
                     {t('coffeeshop.listItem2')}
                  </li>
                  <li className="Coffeeshop__list-item Coffeeshop__icon_barista">
                     {t('coffeeshop.listItem3')}
                  </li>
                  <li className="Coffeeshop__list-item Coffeeshop__icon_water">
                     {t('coffeeshop.listItem4')}
                  </li>
                  <li className="Coffeeshop__list-item Coffeeshop__icon_pets">
                     {t('coffeeshop.listItem5')}
                  </li>
               </ul>
            }
         />

         {/* <SectionInfo
            title={() => <SubTitle>{t('coffeeshop.title3')}</SubTitle>}
            color="var(--brand-color-1)"
            description={() => <ul className="Coffeeshop-text">
               <li>{t('coffeeshop.coffeeItem1')}</li>
               <li>{t('coffeeshop.coffeeItem2')}</li>
               <li>{t('coffeeshop.coffeeItem3')}</li>
               <li>{t('coffeeshop.coffeeItem4')}</li>
               <li>{t('coffeeshop.coffeeItem5')}</li>
               <li>{t('coffeeshop.coffeeItem6')}</li>
               <li>{t('coffeeshop.coffeeItem7')}</li>
               <li>{t('coffeeshop.coffeeItem8')}</li>
            </ul>}
        /> */}
    </div>
  );
}

export default Coffeeshop;
