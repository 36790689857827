import i18next from 'i18next';
import { useState, ChangeEvent, MouseEvent } from 'react';

import './LanguageSwitch.css';

interface LanguageSwitchProps {
    langs: string[];
}

const LanguageSwitch = ({ langs }: LanguageSwitchProps) => {
    const [checked, setChecked] = useState(false)

    const toggleSwitch = (event: ChangeEvent | MouseEvent) => {
        setChecked(!checked)
        i18next.changeLanguage(checked ? langs[0] : langs[1])
    }

    return (
        <div className="switch">
            <span>
                <input 
                    type="checkbox"
                    id="toggleInput"
                    checked={checked}
                    onChange={toggleSwitch}
                />
                <button
                    className={'slider ' + (checked ? 'slider_true' : 'slider_false')}
                    type="button"
                    onClick={toggleSwitch}
                >
                    { !checked ?
                        <div className="button__text button__text_right  ">{langs[0]}</div> :
                            <div className="button__text button__text_left">{langs[1]}</div> }
                </button>
            </span>            
        </div>
    );
}

export default LanguageSwitch;
