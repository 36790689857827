import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SectionInfo from '../SectionInfo/SectionInfo';
import Title from '../Title/Title';

import 'react-awesome-slider/dist/styles.css';
import './InfoBlock.css';

const InfoBlock = () => {
  const arr = ['work', 'events', 'design', 'illustration', 'development', 'coding', 'coffee', 'coworking', 'and more *'];
  const [word, setWord] = useState(arr[0])
  var i = 0;

  function changeText(){
    if (i !== arr.length){
      setWord(arr[i]); 
      i++;
    } else {
      i=0;
      setWord(arr[i])}
    };

  useEffect(() => {
    let interval = setInterval(changeText, 3000);
  
    return () => clearInterval(interval);
  }, []);

  const { t } = useTranslation();

  return (
    <div className="InfoBlock">
      <div className="InfoBlock__wrapper">
        <SectionInfo title={() => <Title>{t('home.title')}</Title>} color="var(--brand-color-2)"/>
        <section className="InfoBlock__section section-media"></section>
        <p className="InfoBlock__words">I love <span className="InfoBlock__word"><strong>{word}</strong></span></p>
      </div>
    </div>
  );
}

export default InfoBlock;
