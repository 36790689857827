import './Burger.css';

interface BurgerProps {
  open: boolean;
  setOpen: (open: boolean) => void
}

const Burger = ({ open, setOpen }: BurgerProps) => {
  return (
    <button className="Burger" onClick={() => setOpen(!open)}>
        <span className={'Burger-line ' + (open ? 'Burger-line_open' : 'Burger-line_close')}></span>
        <span className={'Burger-line ' + (open ? 'Burger-line_open' : 'Burger-line_close')}></span>
        <span className={'Burger-line ' + (open ? 'Burger-line_open' : 'Burger-line_close')}></span>
    </button>
  );
}

export default Burger;
