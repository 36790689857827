import React from 'react';
import './Loader.css';

interface LoaderProps {
  color: string;
}

const Loader: React.FC<LoaderProps> = (props) => {
  return (
    <div className="root root-bg">
      <svg
        width={60}
        height={60}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        style={{ background: 'none' }}
      >
        <circle
          cx="50"
          cy="50"
          fill="none"
          stroke={props.color}
          strokeWidth="5"
          r="35"
          strokeDasharray="165"
          strokeDashoffset="165"
          className="circle"
        />
      </svg>
    </div>
  );
};

export default Loader;
