import './Nav.css';

interface NavProps {
  value: string;
  onClick?: () => void;
}

const Nav = ({ value, onClick }: NavProps) => {
  return (
    <li className="Nav">
        <a className="Nav__text" onClick={onClick}>{value}</a>
    </li>
  );
}

export default Nav;
