import { ReactNode } from 'react';
import './SectionInfo.css';

interface SectionInfo {
  description?: string | Function;
  title: () => ReactNode;
  color: string;
  className?: string;
}

const SectionInfo = ({ description, title, color, className }: SectionInfo) => {
  return (
    <div className={"SectionInfo " + className} style={{backgroundColor: color}}>
      {title()}

      {typeof description === 'string' ? <div className="SectionInfo-body">
        <p className="SectionInfo-desc">{description}</p>
      </div> : typeof description === 'function' ? <div className="SectionInfo-body">
        <p className="SectionInfo-desc">{description()}</p>
      </div> : ''}
    </div>
  );
}

export default SectionInfo;
