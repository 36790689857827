import { Element } from 'react-scroll';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import {
  Map,
  InfoBlock,
  Footer,
  Coworking,
  Coffeeshop
} from '../../components';

const HomePage = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Helmet>
        <title>{t('head.title')}</title>
        <meta name="description" content={t('head.description') ?? ''}/>
        <link rel="canonical" href="https://lettersandnumbers.am" />
      </Helmet>

      <Element id="home" className="element" name="home">
        <InfoBlock />
      </Element>

      <Element id="coworking" className="element" name="coworking">
        <Coworking />
      </Element>

      <Element id="coffeeshop" className="element" name="coffeeshop">
        <Coffeeshop />
      </Element>

      {/* <Events/> */}

      <Map />

      <Element id="contacts" className="element" name="contacts">
        <Footer />
      </Element>
    </div>
  );
}

export default HomePage;
