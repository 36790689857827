import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import './Button.css';

interface ButtonProps {
    to: string;
}

const Button = ({ children, to }: PropsWithChildren<ButtonProps>) => {
    let navigate = useNavigate();

    const goTo = () => {
        if (to) {
            navigate(to)
        }
    }

    return (
        <button className="Button" onClick={goTo}>{children}</button>
    )
}

export default Button;