import { Button, SocialIcon } from '../../components';
import TelegramIcon from '../../assets/telegram.png';

import './ErrorPage.css';

const ErrorPage = () => {
  return (
    <div className="ErrorPage ErrorPage-content ErrorPage_bg">
        <h1 className="ErrorPage-title ErrorPage-text">OOPS!</h1>
        <p className="ErrorPage-desc ErrorPage-text">Looks like something went wrong. Contact us <SocialIcon src={TelegramIcon} alt="telegram" href="https://t.me/lan_yerevan"/></p>
        <Button to="/">Go to Home</Button>
    </div>
  );
}
  
export default ErrorPage;
  