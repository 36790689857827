import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Element } from 'react-scroll';
import { TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import * as yup from 'yup';
import { Link as CustomLink } from '../../components';

import './NewYearPage.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const NewYearPage = () => {
    const [submited, setSubmited] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState('');

    const validationSchema = yup.object({
        name: yup
          .string()
          .required('Давайте познакомимся! Как к вам обращаться?'),
        email: yup
          .string()
          .email('Введите действительный адрес электронной почты')
          .required('Напишите ваш email для связи'),
        phone: yup
          .string()
          .required('Ваш телефон?'),
      });

    const submitForm = async (values: any) => {
        try {
            setSubmitting(true);

            await fetch(`${process.env.REACT_APP_BASE_URL}/api/b2b/request`, {
                method: 'POST',
                body: JSON.stringify({
                    name: values.name,
                    email: values.email,
                    phone: values.phone,
                    comment: values.comment,
                })
            });
            formik.handleReset(() => {});

            setSubmited(true);
        } catch (err) {
          console.warn(err);
          setError(`${err}`);
        } finally {
            setSubmitting(false);
        }
    }

    const formik = useFormik({
        initialValues: {
          name: '',
          email: '',
          phone: '',
          comment: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
          submitForm(values);
        },
    });

    return (
        <div className="nyp">
            <Helmet>
                <title>Площадка для незабываемого Нового года в Ереване — Ваш идеальный праздник</title>
                <meta name="description" content="Ищете место для проведения Нового года в Ереване? Мы предлагаем уютную атмосферу, первоклассный сервис и всё необходимое для вашего идеального праздника. Забронируйте сейчас!"/>
                <link rel="canonical" href="https://lettersandnumbers.am/new-year-2025" />
            </Helmet>
            
            <header className="nyp-header">
                <div className="nyp-header-content">
                    <h1 className="nyp-header-title nyp-title-h1">Идеальная Площадка для<br />Новогоднего Корпоратива 2025🎉</h1>

                    <p className="nyp-header-desc nyp-paragraph">Создайте незабываемую атмосферу для вашего новогоднего корпоратива!</p>
                    
                    <Link to="contact" spy={true} smooth={true} duration={500} offset={-100}>
                        <a className="nyp-button nyp-button_booking">Забронировать сейчас</a>
                    </Link>
                </div>
            </header>

            <section className="nyp-section nyp-section-about">
                <h2 className="nyp-title-h2 nyp-section-title ">Просторное и стильное пространство 🏡🌿</h2>
                <p className="nyp-paragraph">Наше место идеально подходит для проведения корпоративов команд до 40 человек. Удобная локация и продуманная инфраструктура — все для вашего комфорта.</p>
            </section>

            <section className="nyp-section">
                <h2 className="nyp-title-h2 nyp-section-title">Всё необходимое для успешного мероприятия 🎉✔️</h2>
                <div className="nyp-service-list">
                    <div className="nyp-service-item item-img1">
                        <h3 className="nyp-title-h3 item-content">Оборудование для мероприятий</h3>
                    </div>

                    <div className="nyp-service-item item-img2">
                        <h3 className="nyp-title-h3 item-content">Кейтеринг с разнообразным меню</h3>
                    </div>

                    <div className="nyp-service-item item-img3">
                        <h3 className="nyp-title-h3 item-content">Собственный бар и кофейня</h3>
                    </div>

                    <div className="nyp-service-item item-img4">
                        <h3 className="nyp-title-h3 item-content">Бесплатный Wi-Fi</h3>
                    </div>

                    <div className="nyp-service-item item-img5">
                        <h3 className="nyp-title-h3 item-content">Командные мероприятия под ключ</h3>
                    </div>
                </div>
            </section>

            <section className="nyp-section">
                <h2 className="nyp-title-h2 nyp-section-title">Посмотрите, как это выглядит 🖼️🛋️</h2>
                <div className="nyp-gallery">
                    <Swiper
                        slidesPerView={1}
                        breakpoints={{
                            640: {
                              slidesPerView: 1,
                              spaceBetween: 20,
                            },
                            768: {
                              slidesPerView: 2,
                              spaceBetween: 40,
                            },
                            1024: {
                              slidesPerView: 3,
                              spaceBetween: 50,
                            },
                          }}
                        spaceBetween={30}
                        pagination={{
                        clickable: true,
                        }}
                        className="mySwiper-desctop"
                        modules={[Autoplay, Pagination, Navigation]}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                    >
                        <SwiperSlide>
                            <img src="images/coworking-space.jpg" alt="New Year Venue 1"/>
                        </SwiperSlide>
                        
                        <SwiperSlide>
                            <img src="images/cozy-space.jpg" alt="New Year Venue 2"/>
                        </SwiperSlide>
                        
                        <SwiperSlide>
                            <img src="images/quiet-workplace.jpg" alt="New Year Venue 4"/>
                        </SwiperSlide>

                        <SwiperSlide>
                            <img src="images/comfortable-workplace.jpg" alt="New Year Venue 5"/>
                        </SwiperSlide>

                        <SwiperSlide>
                            <img src="images/meeting-room.jpg" alt="New Year Venue 6"/>
                        </SwiperSlide>

                        <SwiperSlide>
                            <img src="images/individual-workplace.jpg" alt="New Year Venue 3"/>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>

            <section className="nyp-section">
                <h2 className="nyp-title-h2 nyp-section-title">Цены и условия бронирования 💰📊</h2>
                <p className="nyp-paragraph">Мы рады предложить вам несколько вариантов площадки. Чтобы забронировать идеальный для вас вариант, заполните форму в разделе контакты.</p>
            </section>

            <section className="nyp-section">
                <Element id="contact" name="contact">
                    <h2 className="nyp-title-h2 nyp-section-title">Свяжитесь с нами 📞💬</h2>
                </Element>               
                <p className="nyp-paragraph">Если у вас есть вопросы или вы хотите узнать больше, свяжитесь с нами любым удобным способом.</p>
                    {!submited ? (<form  className="nyp-form" onSubmit={formik.handleSubmit}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="name"
                            name="name"
                            label="Ваше имя"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />

                        <TextField
                            variant="outlined"
                            fullWidth
                            id="email"
                            name="email"
                            label="Email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                        
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="phone"
                            name="phone"
                            label="Телефон"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.phone && Boolean(formik.errors.phone)}
                            helperText={formik.touched.phone && formik.errors.phone}
                        />

                        <TextField
                            variant="outlined"
                            fullWidth
                            id="comment"
                            name="comment"
                            label="Сообщение"
                            value={formik.values.comment}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.comment && Boolean(formik.errors.comment)}
                            helperText={formik.touched.comment && formik.errors.comment}
                            multiline
                            minRows={4}
                        />

                        <button className="nyp-button nyp-button_booking" type="submit">{submitting ? 'Отправляю...' : 'Отправить'}</button>

                        {error.length > 0 ? <p className="nyp-error">В данный момент выполнение операции невозможно. Пожалуйста, повторите попытку позже или <CustomLink className='nyp-link-blue' href="https://t.me/lan_yerevan">напишите нам в Telegram</CustomLink></p> : null}
                    </form>) : (
                        <div className="nyp-form-success">
                           <div className="nyp-form-success-content">
                                <h2 className="nyp-title-h1 nyp-header-title">Спасибо!<br/>Ваша форма успешно отправлена. Мы скоро свяжемся с вами!</h2>
                           </div>
                        </div>)}
            </section>
 
            <section className="nyp-section">
                <h2 className="nyp-title-h2 nyp-section-title">Отзывы 💬👍</h2>
                <Swiper
                    slidesPerView={'auto'}
                    spaceBetween={30}
                    pagination={{
                        clickable: false,
                    }}
                    modules={[Autoplay, Navigation]}
                    className="mySwiper nyp-reviews"
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                >
                        <SwiperSlide>
                            <p className="testimonials">
                                "Наша команда была в полном восторге! Пространство уютное, обслуживание великолепное, а настроение — просто сказка!" — Ольга, Маркетолог.
                            </p>
                        </SwiperSlide>
                        
                        <SwiperSlide>
                            <p className="testimonials">
                                "Организация мероприятия прошла безупречно. Отдельное спасибо за внимание к деталям, это был вечер, который мы запомним надолго!" — Дмитрий, Руководитель отдела IT.
                            </p>
                        </SwiperSlide>
                        
                        <SwiperSlide>
                            <p className="testimonials">
                                "Превосходная площадка для проведения мероприятий. Всё было настолько идеально, что никто не хотел уходить!" — Елена, Руководитель PR-отдела.
                            </p>
                        </SwiperSlide>

                        <SwiperSlide>
                            <p className="testimonials">
                                "Новогодний корпоратив превзошел все ожидания! Вкусная еда, отличная музыка и невероятная атмосфера!" — Александр, Директор по развитию.
                            </p>
                        </SwiperSlide>

                        <SwiperSlide>
                            <p className="testimonials">
                                "Спасибо за незабываемый вечер! Коллеги довольны, всё прошло на высшем уровне. Рекомендую всем!" — Наталья, Финансовый аналитик.
                            </p>
                        </SwiperSlide>

                        <SwiperSlide>
                            <p className="testimonials">
                                "Место с потрясающей энергетикой! Сервис и профессионализм команды превратили наше мероприятие в настоящее волшебство!" — Андрей, Руководитель отдела продаж.
                            </p>
                        </SwiperSlide>
                    </Swiper>
            </section>

            <footer className="nyp-footer">
                <p className="nyp-footer-paragraph">Контактная информация: <CustomLink href='tel:+374 94 601 303' alt='phone link' className='nyp-link'>+374 94 601 303</CustomLink> | <CustomLink href="mailto:info@creativecommunity.space" alt='email link' className='nyp-link'>info@creativecommunity.space</CustomLink></p>
                <p><CustomLink className='nyp-link' href="https://t.me/lan_yerevan">Связаться через Telegram</CustomLink></p>
            </footer>
        </div>
    )
}

export default NewYearPage;