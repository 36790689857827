import './SocialIcon.css';

interface SocialIconProps {
  src: string;
  alt: string;
  href: string;
}

const SocialIcon = ({ src, alt, href }: SocialIconProps) => {
  return (
    <a className="SocialIcon" href={href} target="_blank" rel="noreferrer">
      <img className="SocialIcon__img" src={src} alt={alt}/>
    </a>
  );
}

export default SocialIcon;
