const wait = (delay: number) => {
    return new Promise((resolve) => setTimeout(resolve, delay));
}

export async function fetchRetry(url: string, retryDelay: number = 5000, requestTimeout = 15000, tries: number = 10, fetchOptions = {
}) {   
    let t = tries;
    const controller = new AbortController()

const timeoutId = setTimeout(() => {
  controller.abort();

  new Error('timeout');
}, requestTimeout)

    while(t > 0) {
      try {
        const res = await wait(t === tries ? 0 : retryDelay).then(() => fetch(url, { ...fetchOptions, signal: controller.signal }).then(response => {
          clearTimeout(timeoutId);
          return response;
        }));

        t -= 1;

        return res;
      } catch(error) {
        throw new Error(`[fetch]: ${error}`);
      }
    }
  
    throw new Error(`[fetch]: Too many retries`);
  }  
