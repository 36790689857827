import { useTranslation } from 'react-i18next';
import { SectionInfo, SubTitle } from '../';
import './About.css';

const About = () => {
  const { t } = useTranslation();

  return (
    <div className="About">
      <section className="About-media"></section>
      <SectionInfo
        title={() => <SubTitle>{t('about.title')}</SubTitle>}
        description={t('about.text') ?? ''}
        color="var(--brand-color-1)"
      />
    </div>
  );
}

export default About;
